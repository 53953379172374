import React, { useState, useRef } from "react"
import { Text } from "../Core"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus"
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus"

const Accordion = ({ label, question, answer }) => {
  const answerRef = useRef()

  const [answerHeight, setAnswerHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const toggleAnswer = () => {
    if (isOpen) {
      setAnswerHeight(0)
    } else {
      setAnswerHeight(answerRef.current.scrollHeight + "px")
    }
    setIsOpen(!isOpen)
  }

  const styles = {
    maxHeight: answerHeight
  }

  return (
    <div class="faq__outer">
      <div class={`faq__inner ${isOpen ? "faq__open" : ""}`}>
        <div class="faq__question" onClick={toggleAnswer}>
          <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} color="#545454" />
          <p className="faq__label">{label}</p>
        </div>

        <p class="faq__answer" style={styles} ref={answerRef}>
          <Text text={answer} />
        </p>
      </div>
    </div>
  )
}

export default Accordion
