import { graphql, Link } from "gatsby"
import React from "react"
import SEO from "../components/seo"

import Navbar from "../components/Menu/LifestyleNav"
import { Button } from "../components/Button"
import { Image, Text } from "../components/Core"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Seamless from "../components/Seamless"
// import { PrivacyFooter } from "./lifestyle"
import { telephoneStrip } from "../utils/utils"
import { Accordion } from "../components/Accordion"

const LifestylePatientPage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    isRobotsIndex,
    hero,
    about,
    form,
    quote,
    phone,
    videoTranscription
  } = data.allLifestylePatientsJson.nodes[0]

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        robots={isRobotsIndex ? "noindex" : ""}
      />
      <nav className="ls-patient-nav" id="mainNav">
        <Image publicId="RCOMS/DEV/rcoms-logo" />
        <Button
          buttonText={phone}
          href={`tel:+1${telephoneStrip(phone)}`}
          destination="tel"
          contained
        />
      </nav>
      {/* add 'buffer' classname to line below if has image */}
      <div className="lifestyle ls__patient">
        <div className="ls__section">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <h1
                className="has-text-centered mb-0"
                dangerouslySetInnerHTML={{ __html: hero.heading }}
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <div className="ls__section">
          <div className="columns is-vcentered">
            <div className="column is-3"></div>
            <div className="column">
              <Image useAR cloudName="nuvolum" publicId={quote.image} />
            </div>
            <div className="column is-1"></div>
            <div className="column mt-2--mobile">
              <p className="mt-3-mobile mb-2 ls__patient-quote">
                <i className="bold">{`"${quote.text}"`}</i>
              </p>
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${hero.youtube.code}`}
                controls
                playing
                buttonClass="ls__theater-btn contained"
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <div className="ls__section">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <Accordion
                label="Video Transcription"
                answer={videoTranscription}
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <div className="ls__section">
          <div className="columns is-desktop">
            <div className="column is-3"></div>
            <div className="column">
              <h2>{about.heading}</h2>
              <Text text={about.blurb} />
            </div>
            <div className="column is-1"></div>
            <div className="column">
              <div className="ls__form-sidebar">
                <Text text={form.content} />
                <Seamless src={form.url} />
              </div>
            </div>
            <div className="column is-3"></div>
          </div>
        </div>
      </div>
      <footer className="ls__privacy-footer">
        <Link to="/privacy-policy/" title="Read the privacy policy">
          Privacy Policy
        </Link>
        <Link to="/terms-of-use/" title="Read the terms of use">
          Terms of Use
        </Link>
        <Link
          to="/accessibility-statement/"
          title="Read the ADA accessibility statement">
          ADA Accessibility Statement
        </Link>
        <Link to="/hipaa-compliance/" title="Read the HIPAA compliance">
          HIPAA Compliance
        </Link>
      </footer>
    </>
  )
}

export const lifestylePatientPageQuery = graphql`
  query lifestylePatientPage($title: String!) {
    allLifestylePatientsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        isRobotsIndex
        videoTranscription
        phone
        hero {
          heading
          youtube {
            code
            image
          }
        }
        about {
          heading
          blurb
        }
        form {
          content
          url
        }
        quote {
          text
          image
        }
      }
    }
  }
`

export default LifestylePatientPage
